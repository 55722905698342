import { ThemeProvider, CSSReset, theme, Box } from "@chakra-ui/react";
import Footer from "./footer";
import Header from "./header";
import React, { useState, useEffect } from "react";
import PopUp from "./popUp";
import useSWR from "swr";
import MenuBar from "./navbar";
import { GetCookie } from "../provider/common";
import VOC from "../components/board/voc"; // Import the new component

export default function IndexLayout({ children }) {
  const [showBox, setShowBox] = useState(false);
  const [isLogin, setIsLogin] = useState(0);

  const checkLogin = async () => {
    const loginInfo = await GetCookie("isLogin");
    setIsLogin(loginInfo);
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const openVocModal = () => {
    setShowBox(true);
  };

  const closeVocModal = () => {
    setShowBox(false);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLogin === "1" && (
          <>
            <div className="scroll_container">
              <button id="saleBtn" onClick={openVocModal}>
                <i></i>
              </button>
            </div>

            <VOC showBox={showBox} closeModal={closeVocModal} />
          </>
        )}

        <Header />
        <Box>
          <main>{children}</main>
        </Box>
        <Footer />
        <MenuBar />
      </ThemeProvider>
    </>
  );
}
